function createObject() {
    let example = {
        id: 'OABD_2',
        workOrder: `
            Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        image: require("@/assets/ove/Stempeln/OABD_2_1.png"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Welche Aussage lässt sich anhand der Dokumente von John belegen?",
                    answers: [{
                            correct: false,
                            text: "Das Konstanzgesetz der Summe ist korrekt dargestellt.",
                            //additionalExplanation: "",
                            response: `Das Konstanzgesetz der Summe ist hier fälscherweise angewandt worden. Da eine Multiplikationsaufgabe vorliegt, müsste gegensinnig multipliziert und dividiert werden.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: true,
                            text: "Die räumlich-simultanen Darstellungen von beiden Rechnungen gelingen.",
                            response: `Genau. Die Übertragung beider einzelner Aufgaben in eine Plättchendarstellung gelingt.`,
                        },
                        {
                            correct: false,
                            text: "Das Beherrschen des Kommutativgesetzes kommt in der Darstellung der eigenen Idee zum Ausdruck.",
                            response: `Das stimmt so nicht. Das Kommutativgesetz wird hier nicht angewandt, dabei würden Multiplikator und Multiplikand vertauscht werden.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "In welchem Bereich vermuten Sie auf Grundlage der gezeigten Dokumente noch Schwierigkeiten?",
                    answers: [{
                            correct: true,
                            text: "Das Erkennen von Aufgabenbeziehungen bei der Multiplikation scheint noch Schwierigkeiten zu bereiten.",
                            //additionalExplanation: "",
                            response: `Es scheint John noch schwer zu fallen zu sehen, dass seine beiden Ausgaben nicht dasselbe ergeben. Eine additive Veränderung beider Zahlen ergibt in der Multiplikation nicht unbedingt dasselbe Ergebnis.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Die Darstellungen der Aufgaben sind zu einseitig und zeigen daher keine richtige Grundvorstellung.",
                            response: `Das stimmt so nicht. Die beiden Aufgaben sind unabhängig voneinander richtig in die Plättchendarstellung übertragen. Der Zusammenhang wird aber weder in der Rechnung noch in der Darstellung deutlich.`,
                        },
                        {
                            correct: false,
                            text: "Das Konstanzgesetz der Summe scheint noch nicht verstanden zu sein.",
                            response: `Das kann so nicht gesagt werden. Ob John das Konstanzgesetz der Summe richtig anwenden kann, wird in diesem Beispiel nicht deutlich.`,
                        },
                    ]
                },

                {
                    question: "Welche Diagnoseaufgabe würden Sie dem Kind stellen, sodass Sie überprüfen können, ob der von Ihnen vermutete Fehler zutrifft?",
                    answers: [{
                            correct: true,
                            text: "Rechne die Aufgabe 18⋅4 möglichst geschickt. Erkläre, warum Du so veränderst.",
                            //additionalExplanation: "",
                            response: `Diese Aufgabe hätten wir auch gewählt. So können Sie gut erkennen, ob John nur einmal den Fehler gemacht hat, additiv zu verändern. Durch seine Erklärung bekommen Sie zudem noch mehr Einsichten in seine Denkweise.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Rechne aus. a) 12⋅7	b) 8⋅17	c) 6⋅9	d) 2⋅14",
                            response: `Das würde wir nicht wählen. Hier erfahren Sie kaum etwas darüber, wie John rechnet. So können Sie “nur“ überprüfen, ob die Ergebnisse richtig sind.`,
                        },
                        {
                            correct: false,
                            text: "Welche Aufgaben haben dasselbe Ergebnis. Verbinde. <br /><span  style='white-space: pre'>3⋅7		 12+12		 6⋅4		 42:2		 13+8<br/>48:2		 8⋅3		 5⋅5		123-99	 	18+6<span>",
                            response: `Das würde wir nicht wählen. Diese Aufgaben fokussieren lediglich dasselbe Ergebnis, nicht aber die Beziehungen zwischen den Aufgaben – falls es überhaupt welche gibt. Um aber die Aufgabenbeziehungen geschickt nutzen zu können, müssen diese auch aktiv thematisiert werden.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();