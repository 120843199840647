// "C:\Users\tabea\Documents\FH Dortmund\FALEDIA\NextCloud\faledia.all.project\Lernbausteine\OV\Lernbausteine_Diagnose\Aufgabenauswahl\Vorlage_Lernbaustein_Gruppen_Diagnose_Aufgaben_V2.pptx"
function createObject() {
    let example = {
        id: 'Gruppen_Diagnose',
        showGroupNames: true,
        checkAfterHowManyItems: 3,
        checkAfterExactItemCount: true,
        workOrder: `
            Welche der drei Hintergrundbereiche – Grundvorstellungen, Darstellungswechsel und Aufgabenbeziehungen – wird durch die jeweilige Aufgabe fokussiert? Es gehören immer genau 3 Aufgaben in eine Gruppe. Ziehen Sie in die entsprechende Gruppe. 
            `,
        possibleCategories: [{
                id: 1,
                name: 'Grundvorstellung (GV)'
            },
            {
                id: 2,
                name: 'Darstellungswechsel (DW)'
            },
            {
                id: 3,
                name: 'Aufgabenbeziehungen (AB)'
            },
        ],
        elements: [{
                id: 1,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_01.png'),
                category: 1,
                flex: 3
            },
            {
                id: 2,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_02.png'),
                category: 3,
                flex: 3
            },
            {
                id: 3,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_03.png'),
                category: 2,
                flex: 3
            },
            {
                id: 4,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_04.png'),
                category: 1,
                flex: 3
            },
            {
                id: 5,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_05.png'),
                category: 3,
                flex: 3
            },
            {
                id: 6,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_06.png'),
                category: 1,
                flex: 3
            },
            {
                id: 7,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_07.png'),
                category: 2,
                flex: 3
            },
            {
                id: 8,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_08.png'),
                category: 2,
                flex: 3
            },
            {
                id: 9,
                image: require('@/assets/ove/Gruppen_Diagnose/gruppen_diagnose_09.png'),
                category: 3,
                flex: 3
            },
        ]
    }

    const zweiGV = `zwei der Aufgaben fokussieren die Grundvorstellungen, `;

    const zweiDW = `zwei der Aufgaben fokussieren den Darstellungswechsel, `;

    const zweiAB = `zwei der Aufgaben fokussieren die Aufgabenbeziehungen, `;

    const einsGV = `eine hingegen befasst sich eher mit den Grundvorstellungen. `;

    const einsDW = `eine hingegen befasst sich eher mit dem Darstellungswechsel. `;

    const einsAB = `eine hingegen befasst sich eher mit den Aufgabenbeziehungen. `;

    const nichtganz = `Das stimmt noch nicht ganz, `;

    const dreiDW = `Super! Diese drei Aufgaben fokussieren den Darstellungswechsel. Hier werden die Kinder angeregt, aktiv die Darstellungen der Malaufgabe zu wechseln. `;

    const dreiAB = `Genau. Diese drei Aufgaben fokussieren die Aufgabenziehungen. Hierbei stehen vor allem Veränderungen und Gemeinsamkeiten von Aufgaben im Vordergrund. `;

    const dreiGV = `Gut. Diese drei Aufgaben legen besonders viel wert auf die Grundvorstellungen. Vor allem soll dabei geschaut werden, inwieweit die Kinder über Grundvorstellungen verfügen und welche sie jeweils aktivieren. `;

    const alleseins = `Das ist noch sehr durcheinander. Jede Aufgabe fokussiert etwas anderes. Schauen Sie noch einmal, was genau von den Kindern gefordert wird, wenn sie diese Aufgaben lösen sollen. `;

    const DWvsGV = `Überlegen Sie noch einmal, ob der Wechsel der Darstellung und die Aktivierung einer Grundvorstellung im Fokus steht. `;

    example.responses = [{
            id: '300',
            category: example.possibleCategories[0], // Array Index not id
            text: dreiGV
        },
        {
            id: '030',
            category: example.possibleCategories[1], // Array Index not id
            text: dreiDW
        },
        {
            id: '003',
            category: example.possibleCategories[2], // Array Index not id
            text: dreiAB
        },
        {
            id: '201',
            text: nichtganz + zweiGV + einsAB
        },
        {
            id: '210',
            text: nichtganz + zweiGV + einsDW + DWvsGV
        },
        {
            id: '120',
            text: nichtganz + zweiDW + einsGV + DWvsGV
        },
        {
            id: '021',
            text: nichtganz + zweiDW + einsAB
        },
        {
            id: '102',
            text: nichtganz + zweiAB + einsGV
        },
        {
            id: '012',
            text: nichtganz + zweiAB + einsDW
        },
        {
            id: '111',
            text: alleseins
        },
    ];

    return example;
}

export default createObject();