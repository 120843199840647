<template>
  <div>
    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <h2 id="diagnose">Diagnose</h2>
    <p>
      Nachdem Du Dir das Hintergrundwissen erarbeitet hast, baut nun der
      Diagnoseteil darauf auf:
    </p>
    <p>
      Auch wenn das Hintergrundwissen essentiell ist, ist es nicht weniger
      wichtig zu trainieren, dieses Wissen auf konkrete Dokumente von Kindern
      anwenden zu können. Vor allem ist es dabei zentral erfassen zu können, in
      welchen Bereichen des Operationsverständnis bereits gut ausgebildet ist
      und wo eventuell Probleme vorliegen. So kann eine Diagnose sowohl
      bereichsspezifisch gestaltet sein, wenn erste Indizien vorliegen, dass
      beispielsweise ein Problem beim Darstellungswechsel vorliegt. Es kann aber
      auch eine Standortbestimmung gestaltet sein, die alle drei Bereiche des
      Operationsverständnisses abdeckt. Dabei werden andere Herausforderungen an
      eine Lehrkraft gestellt. Bei Diagnosen im Allgemeinen sind sowohl
      Aufgabenauswahl als auch -analyse von immenser Bedeutung.
    </p>
    <p>
      Es ist im Unterricht häufig so, dass Kinder nicht nur bei einem
      Schwerpunkt Schwierigkeiten haben. Um nun also einige Dokumente
      detaillierter zu betrachten, bildet die folgende Aktivität Einblicke in
      drei unterschiedliche Dokumente von Kindern. Dazu überlege Dir zuerst, was
      schon gut gelingt. Anschließend soll geschaut werden, inwiefern
      Schwierigkeiten vorliegen und wie das mit weiteren Diagnoseaufgaben
      überprüft werden kann.
    </p>

    <p>
      Schau Dir dazu zuerst ein Malquartett an, bei dem Clarissa 4 Karten zu der
      Aufgabe 5 · 7 notiert hat.
    </p>

    <LernbausteinStempeln
      :id="LBSTStempeln[0].id"
      :workOrder="LBSTStempeln[0].workOrder"
      :image="LBSTStempeln[0].image"
      :audio="LBSTStempeln[0].audio"
      :elements="LBSTStempeln[0].elements"
    />

    <p>Es folgt ein weiteres Malquartett von Ninon.</p>
    <LernbausteinStempeln
      :id="LBSTStempeln[1].id"
      :workOrder="LBSTStempeln[1].workOrder"
      :image="LBSTStempeln[1].image"
      :audio="LBSTStempeln[1].audio"
      :elements="LBSTStempeln[1].elements"
    />

    <p>
      Die Unterschiede zwischen Ninons und Clarissas Malquartett sind deutlich
      erkennbar, auch wenn sie auf den ersten Blick an ähnlichen Stellen liegen.
      Beispielsweise bereits die Beschreibung der Malaufgabe als „7 5-mal“ und
      „3 Sechser“ deutet auf unterschiedliche Nutzung bedeutungsbezogener
      Sprache hin. So können die Kinder beim Vorstellungsaufbau unterstützt
      werden, indem möglichst bedeutungsvolle Sprache genutzt wird – Sechser
      gibt somit an, die viele Elemente in einer Gruppe vorhanden sind.
    </p>
    <p>
      Im nächsten Dokument hat John die Aufgabe 12 · 4 möglichst geschickt zu
      rechnen. Beantworte die Fragen der vorangegangenen Aktivitäten auch zu
      seinem Dokument.
    </p>

    <LernbausteinStempeln
      :id="LBSTStempeln[2].id"
      :workOrder="LBSTStempeln[2].workOrder"
      :image="LBSTStempeln[2].image"
      :audio="LBSTStempeln[2].audio"
      :elements="LBSTStempeln[2].elements"
    />

    <p>
      Es zeigt sich: Sowohl die Kompetenzen, die Fehler als auch die Ursachen
      für diese Fehler können sehr unterschiedlich sein. Insgesamt ist es
      wichtig, dass die Lehrkraft die verschiedenen Facetten des umfassenden
      Operationsverständnisses kennt und diese aktiv in den Unterricht einbaut,
      sodass die Schülerinnen und Schüler möglichst zahlreiche und umfassende
      Möglichkeiten zum Ausbau dieser (Teil-)Kompetenzen erhalten. Es kann
      beispielsweise Kinder geben, die die Malaufgaben bereits automatisiert
      haben und die Rechnungen richtig notieren, doch keine eigene
      Rechengeschichte angeben können, die Rückschlüsse auf eine
      Grundvorstellung gibt. Um dies zu diagnostizieren – und dann anschließend
      zu fördern oder zu fordern – ist es essentiell, dass auch solche Aufgaben,
      die nach der Grundvorstellung fragen, gestellt werden, um dies zum einen
      aufzudecken und dem zum anderen entgegenzuwirken.
    </p>
    <p>
      Dabei ist die Aufgabenauswahl, die die Lehrkraft trifft ein zentraler
      Punkt, um alle Facetten abzudecken. Dazu sollen die folgenden Aufgaben
      danach gruppiert werden, welchen der drei Hintergrundbereiche –
      Grundvorstellungen, Darstellungswechsel und Aufgabenbeziehungen – sie
      fokussieren. Sicherlich schwingen einige Teilbereiche eines umfassenden
      Operationsverständnis teilweise auch in andere Bereiche mit. So ist der
      Darstellungswechsel beispielsweise auch gegeben, wenn die Kinder zu einer
      Aufgabe eine Rechengeschichte formulieren sollen, anhand deren man
      überprüfen kann, welche Grundvorstellung (bereits) ausgebildet ist. Für
      die folgende Aufgabe soll also geschaut werden, welcher Aspekt durch die
      Aufgabe im Fokus steht.
    </p>

    <LernbausteinGruppen
      :id="LBSTGruppen[0].id"
      :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[0].showGroupNames"
      :workOrder="LBSTGruppen[0].workOrder"
      :possibleCategories="LBSTGruppen[0].possibleCategories"
      :elements="LBSTGruppen[0].elements"
      :responses="LBSTGruppen[0].responses"
    />

    <p>
      Nur mit dem Wissen was zu einen umfassenden Operationsverständnis zählt,
      kann eine themengerechte Diagnose und Förderung erstellt werden.
    </p>

    <br />
    <v-row justify="start">
      <v-btn text color="primary" @click="navigateToHintergrundwissen">
        <v-icon>mdi-arrow-left</v-icon>
        Zurück zu: Hintergrundwissen
      </v-btn>
    </v-row>
    <br />

    <Survey
      assignedSite="ove"
      broderTopic="ov"
      :topics="[
        'Grundvorstellungen',
        'Darstellungswechsel beim Operationsverständnis',
        'Aufgabenbeziehungen',
        'Operationsverständnis allgemein',
      ]"
      :alreadySend="
        $store.state.user.completedSurveys
          ? $store.state.user.completedSurveys.includes('ove')
          : false
      "
      :skipLearningStyle="$store.state.user.completedSurveys.length > 0"
    />
  </div>
</template>

<script>
import Survey from "@/components/faledia/Survey";
import AppHelper from "@/common/AppHelper";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_Diagnose from "./beispiele/Gruppen_Diagnose";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinStempeln_Einzeldiagnose_OGVD_2 from "./beispiele/Einzeldiagnose_OGVD_2";
import LernbausteinStempeln_Einzeldiagnose_ODWD_2 from "./beispiele/Einzeldiagnose_ODWD_2";
import LernbausteinStempeln_Einzeldiagnose_OABD_2 from "./beispiele/Einzeldiagnose_OABD_2";
// import LernbausteinStempeln_Beispiel from "./beispiele/StempelnBeispiel";

export default {
  beforeCreate: function() {
    AppHelper.isUserAllowedRedirectIfNot("ove");
  },
  components: {
    Survey,
    LernbausteinGruppen,
    LernbausteinStempeln,
  },
  data: () => ({
    LBSTGruppen: [LernbausteinGruppen_Diagnose],
    LBSTStempeln: [
      LernbausteinStempeln_Einzeldiagnose_OGVD_2,
      LernbausteinStempeln_Einzeldiagnose_ODWD_2,
      LernbausteinStempeln_Einzeldiagnose_OABD_2,
      // LernbausteinStempeln_Beispiel,
    ],
  }),
  methods: {
    navigateToHintergrundwissen: function() {
      this.$router.push({ name: "ove" });
    },
  },
};
</script>

<style scoped>
.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
}
.frac > span {
  display: block;
  padding: 0.1em;
}
.frac span.bottom {
  border-top: thin solid black;
}
.frac span.symbol {
  display: none;
}
</style>
