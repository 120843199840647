function createObject() {
    let example = {
        id: 'ODWD_2',
        workOrder: `
            Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        image: require("@/assets/ove/Stempeln/ODWD_2/ODWD_2_1.png"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Welche Aussage lässt sich anhand der Dokumente von Ninon belegen?",
                    answers: [{
                            correct: true,
                            text: "Die Grundvorstellung des Zusammenfassens kommt in der Rechengeschichte zum Ausdruck.",
                            //additionalExplanation: "",
                            response: `Genau! In Ninons Rechengeschichte werden Gruppen gleicher Größe – hier die Reihen der Kekse – zu einer Menge zusammengefasst.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Die Grundvorstellung des Wiederholens kommt in der Rechengeschichte zum Ausdruck.",
                            response: `Das stimmt so nicht. Es handelt sich bei der Rechengeschichte nicht um das Wiederholen derselben Handlung.`,
                        },
                        {
                            correct: false,
                            text: "Das Beherrschen des Kommutativgesetzes kommt in der Darstellung der eigenen Idee zum Ausdruck.",
                            response: `Das stimmt so nicht. Anhand der gezeigten Dokumente können keinerlei Aussagen darüber getroffen werden, inwiefern Ninon das Kommutativgesetz verstanden hat.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "In welchem Bereich vermuten Sie auf Grundlage der gezeigten Dokumente noch Schwierigkeiten?",
                    answers: [{
                            correct: false,
                            text: "Die Darstellung der Aufgabe 3⋅6 am Hunderterfeld bereitet noch Probleme.",
                            //additionalExplanation: "",
                            response: `Das stimmt so nicht. Hier sind drei Sechserreihen am Hunderterfeld abgebildet.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Die Darstellungen der Aufgabe 3⋅6 sind zu einseitig und zeigen daher keine richtige Grundvorstellung.",
                            response: `Das stimmt so nicht. Sicherlich ist die Grundvorstellung des Zusammenfassens durch eine räumlich-simultane Anordnung in vielen Darstellungen dominant.`,
                        },
                        {
                            correct: true,
                            text: "Die Darstellung der Aufgabe 3⋅6 am Zahlenstrahl bereitet noch Probleme.",
                            response: `Genau. Hier sieht man zwar, dass Ninon das Ergebnis darstellt. Es fehlt aber die Darstellung der Anzahl der Bögen als Multiplikator und die Bögenlänge als Multiplikand.`,
                        },
                    ]
                },
                {
                    question: "Welche Diagnoseaufgabe würden Sie dem Kind stellen, sodass Sie überprüfen können, ob der von Ihnen vermutete Fehler zutrifft?",
                    answers: [{
                            correct: true,
                            text: "Welche Darstellung passt zu der Aufgabe 2⋅7 am besten?",
                            image: require("@/assets/ove/Stempeln/ODWD_2/ODWD_2_2.png"),
                            //additionalExplanation: "",
                            response: `Diese Aufgabe würden wir auch wählen. Hier kann überprüft werden, welche Teile der Multiplikationsaufgabe von Ninon fokussiert werden. In der eigenen Darstellung wurde lediglich das Ergebnis dargestellt – Darstellungen von Multiplikator und Multiplikand fehlten.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Male ein Bild zu der Aufgabe 2⋅7.",
                            response: `Diese Aufgabe würden wir nicht wählen. Das ist so offen, dass die Kinder nicht unbedingt einen Zahlenstrahl wählen, um die Aufgabe darzustellen. Dann kann das Kind also die Aufgabe (richtig) lösen, ohne dass Sie etwas über den Fehler beim Zahlenstrahl erfahren.`,
                        },
                        {
                            correct: false,
                            text: "Theo hat 2 Spielzeugautos. Janek hat 7-mal so viele. Wie viele Spielzeugautos hat Janek?",
                            response: `Diese Aufgabe würden wir nicht wählen. Diese Aufgabe spricht die Grundvorstellung des Vergleichens an und Ninons Dokumente geben weder Hinweise daraus, dass Ninon über diese Grundvorstellung verfügt, noch, dass sie ihr Probleme bereitet. Daher passt die Aufgabe nicht zu den vermuteten Schwierigkeiten.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();