function createObject() {
    let example = {
        id: 'OGVD_2',
        workOrder: `
            Links sehen Sie ein Kinderdokument. Rechts finden Sie Fragen dazu. Dabei gibt es unterschiedlich viele Fragen pro Entscheidungsstufe. Sie müssen die jeweils vorangegangene Entscheidungsstufe richtig beantworten, um die Fragen der nächsten Stufe beantworten zu können.
            `,
        image: require("@/assets/ove/Stempeln/OGVD_2/OGVD_2_1.png"),
        elements: [
            [
                // this is stage 1
                {
                    question: "Welche Aussage lässt sich anhand der Dokumente von Clarissa belegen?",
                    answers: [{
                            correct: true,
                            text: "Die Grundvorstellung des Vergleichens ist ausgebildet.",
                            //additionalExplanation: "",
                            response: `Genau! Die Rechengeschichte zeigt eine vergleichende Grundvorstellung.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Es ist nur eine Grundvorstellung – die des Vergleichens – ausgebildet.",
                            response: `Das stimmt so nicht. Die Rechengeschichte zeigt natürlich die Grundvorstellung des Vergleichens, aber beispielsweise die Darstellung am Zahlenstrahl lässt vermuten, dass auch das Wiederholen als Grundvorstellung ausgebildet ist.`,
                        },
                        {
                            correct: false,
                            text: "Die Vorstellung der Multiplikation als wiederholte Addition ist besonders ausgebildet.",
                            response: `Das stimmt so nicht. Keines der Dokumente im Malquartett gibt Aufschluss darüber, dass Clarissa die Multiplikationsaufgaben als wiederholte Addition löst.`,
                        },
                    ]
                }
            ],
            [
                // this is stage 2
                {
                    question: "In welchem Bereich vermuten Sie auf Grundlage der gezeigten Dokumente noch Schwierigkeiten?",
                    answers: [{
                            correct: true,
                            text: "Die Darstellung der Aufgabe 5⋅7 am Hunderterfeld bereitet noch Probleme. Hier wird die Aufgabe 5⋅8 dargestellt.",
                            //additionalExplanation: "",
                            response: `Genau. Durch Clarissas Beschriftung lässt sich vermuten, dass das erste Plättchen jeder Zeile nicht zu dem 7er (dem Multiplikand) zugezählt wird, da sie separat den Multiplikator darstellt.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Es ist nur eine Grundvorstellung – die des Vergleichens – aufgebaut, da keine andere für die Aufgabe 5⋅7 gezeigt wird.",
                            response: `Das stimmt so nicht. Zwar zeigt die Rechengeschichte die Grundvorstellung des Vergleichens, aber daraus kann nicht geschlossen werden, dass die anderen Grundvorstellungen nicht aufgebaut sind. Vor allem der Zahlenstrahl gibt Grund zu der Annahme, dass die zeitlich sukzessive Vorstellung bereits angebahnt ist.`,
                        },
                        {
                            correct: false,
                            text: "Die Darstellung bei der eigenen Idee zeigt die Aufgabe 6⋅7 statt der Aufgabe 5⋅7.",
                            response: `Die eigene Idee zeigt zwar sechs 7ner, aber die Idee des Vergleichens wird deutlich, indem eine Grundmenge (hier 7) dargestellt wird und das andere Kind das fünffache davon hat.`,
                        },
                    ]
                },
                // this is stage 3
                {
                    question: "Welche Diagnoseaufgabe würden Sie dem Kind stellen, sodass Sie überprüfen können, ob der von Ihnen vermutete Fehler zutrifft?",
                    answers: [{
                            correct: true,
                            text: "Welche Aufgabe passt zu der Darstellung?",
                            image: require("@/assets/ove/Stempeln/OGVD_2/OGVD_2_1_A.png"),
                            //additionalExplanation: "",
                            response: `Diese Aufgabe würden wir auch wählen. Hier kann überprüft werden, ob der vermutete Fehler, dass die erste Spalte nicht mitgezählt wird, weil sie als separat wahrgenommen wird, zutrifft.`,
                            //additionalExplanationResponse: "",
                        },
                        {
                            correct: false,
                            text: "Male ein Bild zu der Aufgabe 3⋅5.",
                            response: `Diese Aufgabe würden wir nicht wählen. Diese ist so offen, dass die Kinder nicht unbedingt eine Plättchendarstellung wählen, um die Aufgabe darzustellen. Dann kann das Kind also die Aufgabe (richtig) lösen, ohne dass Sie etwas über den Fehler, dass die erste Spalte nicht mitgezählt wird, erfahren.`,
                        },
                        {
                            correct: false,
                            text: "Nazle liest jeden Tag 5 Seiten in ihrem Buch. Wie viele Seiten liest sie in 3 Tagen?",
                            response: `Diese Aufgabe würden wir nicht wählen. Der Aufgabe liegt die Grundvorstellung des Wiederholens zugrunde. Clarissas Lösungen deuten eher darauf hin, dass der Fehler in der räumlichen Anordnung liegt.`,
                        },
                        {
                            correct: false,
                            text: "Welche Mal-Aufgabe passt zum Würfelbild?",
                            image: require("@/assets/ove/Stempeln/OGVD_2/OGVD_2_1_D.png"),
                            response: `Diese Aufgabe würden wir nicht wählen. Die Aufgabe mit Würfelbildern ist doch eine recht andere Darstellung als die räumlich simultane am Hunderterfeld und zeigt nicht unbedingt, ob der Fehler dort reproduziert werden würde.`,
                        },
                    ]
                }
            ],
        ]
    }

    return example;
}

export default createObject();